import React from 'react'

export const order = (varA, varB, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    if (varAIndex > varBIndex) isValid = false;
    return isValid;
}

export const notAdjacent = (varA, varB, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    if (Math.abs(varAIndex - varBIndex) === 1) isValid = false;
    return isValid;
}

export const adjacent = (varA, varB, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    if (Math.abs(varAIndex - varBIndex) > 1) isValid = false;
    return isValid;
}

export const directedAdjacent = (varA, varB, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    if ((varAIndex - varBIndex) != -1) isValid = false;
    return isValid;
}

export const cantGoSpot = (varA, index, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    if (varAIndex === index-1) isValid = false;
    return isValid; 
}

export const mustGoSpot = (varA, index, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    if (varAIndex != index-1) isValid = false;
    return isValid; 
}

export const seperation = (varA, varB, distance, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const space = parseInt(distance);
    if (Math.abs(varAIndex - varBIndex) != space+1) isValid = false;
    return isValid;
}

export const directedSeperation = (varA, varB, distance, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const space = parseInt(distance);
    if ((varBIndex - varAIndex) != space+1) isValid = false;
    return isValid;
}
//if A-B -> C-D
export const ifOrderThenOrder = (varA, varB, varC, varD, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB) || !permutation.includes(varC) || !permutation.includes(varD)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const varCIndex = permutation.indexOf(varC);
    const varDIndex = permutation.indexOf(varD);
    if (varAIndex < varBIndex){
        if (varCIndex > varDIndex) isValid = false;
    }
    return isValid;  
}
//if AB -> C1
export const ifOrderThenMustGoSpot = (varA, varB, varC, index, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB) || !permutation.includes(varC)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const varCIndex = permutation.indexOf(varC);
    if (varAIndex < varBIndex){
        if (varCIndex != index-1) isValid = false;
    }
    return isValid;  
}
// if A1 -> B2
export const ifCertainSpotMustGoSpot = (varA, indexA, varB, indexB, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    if (varAIndex < varBIndex){
        if (varAIndex === indexA-1){
            if (varBIndex != indexB-1) isValid = false;
        } 
    }
    return isValid;  
}
// if A-B then AB
export const ifOrderThenAdjacent = (varA, varB, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    if (varAIndex < varBIndex){
        if (varBIndex - varAIndex != 1) isValid = false;
    }
    return isValid;  
}
//must be at least 2 apart
export const minSeperation = (varA, varB, distance, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const space = parseInt(distance);
    if (Math.abs(varAIndex - varBIndex) < space+1) isValid = false;
    return isValid;
}
//,ust be at least 2 apart A-B
export const minSeperationDirected = (varA, varB, distance, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const space = parseInt(distance);
    if ((varBIndex - varAIndex) < space+1) isValid = false;
    return isValid;
}

//A o B before C
export const twoOptionsBeforeAdjacent = (varA, varB, varC, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB) || !permutation.includes(varC)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const varCIndex = permutation.indexOf(varC);
    if ((varCIndex - varBIndex != 1) && (varCIndex - varAIndex != 1)) isValid = false;
    return isValid;
}
//A before B or C
export const twoOptionsAfterAdjacent = (varA, varB, varC, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB) || !permutation.includes(varC)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const varCIndex = permutation.indexOf(varC);
    if ((varBIndex - varAIndex != 1) && (varCIndex - varAIndex != 1)) isValid = false;
    return isValid;
}

//spot 1 is A or B

export const limitedSpot = (varA, varB, index, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    if (varAIndex != index-1 && varBIndex != index-1) isValid = false;
    return isValid; 
}
//A is in 1 or 2
export const mustGoSpotOptions = (varA, index1, index2, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    if (varAIndex != index1-1 && varAIndex != index2-1) isValid = false;
    return isValid; 
}

//B is inbetween A and C
export const inBetween = (varA, varB, varC, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB) || !permutation.includes(varC)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const varCIndex = permutation.indexOf(varC);
    if ((varBIndex > varCIndex && varBIndex < varAIndex) || varBIndex < varCIndex && varBIndex > varAIndex) return true;
    return false;
}

//B is not inbetween A and C
export const notInBetween = (varA, varB, varC, permutation) => {
    let isValid = true;
    if (!permutation.includes(varA) || !permutation.includes(varB) || !permutation.includes(varC)) return isValid;
    const varAIndex = permutation.indexOf(varA);
    const varBIndex = permutation.indexOf(varB);
    const varCIndex = permutation.indexOf(varC);
    if ((varBIndex > varCIndex && varBIndex < varAIndex) || varBIndex < varCIndex && varBIndex > varAIndex) return false;
    return true;
}


