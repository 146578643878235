import React from 'react'
import Box from "./Box"

const Row = (props) => {
    const permutation = props.perm;
    return(
        <div className="row">   
        {permutation.map((letter, key) => {
            return(
                <Box character={letter}/>
            )
        })}
        </div>

    )
}

export default Row


/*

return(
        <div>
        {permutation.map((letter, key) => {
            return (
            <div><Box character = {letter}/></div>
            )
        })}
        
        

        </div>

    )



*/