import React from 'react'
import inputValue from "../App";
import {useState} from "react";
import Matrix from "./Matrix";
import Row from "./Row";
import {order, notAdjacent, adjacent, directedAdjacent, cantGoSpot, mustGoSpot, seperation, directedSeperation, ifOrderThenOrder, ifOrderThenMustGoSpot, ifCertainSpotMustGoSpot, ifOrderThenAdjacent, minSeperation, minSeperationDirected, twoOptionsAfterAdjacent, twoOptionsBeforeAdjacent, limitedSpot, mustGoSpotOptions, inBetween, notInBetween} from "./Rules";

const Permutations = (props) => {
    const[permutation, setPermutation] = useState([]);
    let results = []
    
    const handlePermChange = () => {
     setPermutation(results);
  }

  const ruleMap = new Map();
  ruleMap.set("order", {order});
  ruleMap.set("mustGoSpot", {mustGoSpot});
  

    

    const dfs = function (nums, current, rules){
        if (current.length === nums.length){
            let valid = true;
            const currentRules = props.rules
            console.log(currentRules);
            props.rules.map((ruleAndVar, key) => {
                const currentFunction = ruleAndVar.rule
                console.log(ruleAndVar.rule)
                const variables = ruleAndVar.vars
                //const var1 = ""
                //const var2 = ""
            
                if (currentFunction === "order"){
                    const var1 = variables.charAt(0).toUpperCase();
                    const var2 = variables.charAt(1).toUpperCase();
                    if (order(var1, var2, current)=== false){
                        valid = false;
                        //break;
                    } 
                }
                if (currentFunction === "mustGoSpot"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    if (mustGoSpot(var1, var2, current) === false) {
                        valid = false;
                        //break;
                }
                }

                if (currentFunction === "notAdjacent"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    if (notAdjacent(var1, var2, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "adjacent"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    if (adjacent(var1, var2, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "directedAdjacent"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    if (directedAdjacent(var1, var2, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "cantGoSpot"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    if (cantGoSpot(var1, var2, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "seperation"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (seperation(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "directedSeperation"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (directedSeperation(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }

                if (currentFunction === "ifOrderThenOrder"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    const var4 = variables.charAt(3);
                    if (ifOrderThenOrder(var1, var2, var3, var4, current)=== false){
                        valid = false;
                    } 
                }

                if (currentFunction === "ifOrderThenMustGoSpot"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    const var4 = variables.charAt(3);
                    if (ifOrderThenMustGoSpot(var1, var2, var3, var4, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "ifCertainSpotMustGoSpot"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    const var4 = variables.charAt(3);
                    if (ifCertainSpotMustGoSpot(var1, var2, var3, var4, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "ifOrderThenAdjacent"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    if (ifOrderThenAdjacent(var1, var2, current) === false){
                        valid = false;
                    } 
                }
                if (currentFunction === "minSeperation"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (minSeperation(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "minSeperationDirected"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (minSeperationDirected(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "twoOptionsBeforeAdjacent"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (twoOptionsBeforeAdjacent(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "twoOptionsAfterAdjacent"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (twoOptionsAfterAdjacent(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "limitedSpot"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (limitedSpot(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "mustGoSpotOptions"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (mustGoSpotOptions(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "inBetween"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (inBetween(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
                if (currentFunction === "notInBetween"){
                    const var1 = variables.charAt(0);
                    const var2 = variables.charAt(1);
                    const var3 = variables.charAt(2);
                    if (notInBetween(var1, var2, var3, current)=== false){
                        valid = false;
                    } 
                }
            
               
            })
            
            if (valid === true) results.push(Array.from(current));
            
            //using array.from allows to pass in value not just reference
            
        }
        for (let i = 0; i < nums.length; i++){
            if (current.includes(nums[i])) continue;
            current.push(nums[i]);
            dfs(nums, current, results);
            current.pop();
        }
        
        
    }
    
    const findPermutations = function(size, letters, rules){
        
        const nums = [];
        for (var i = 0; i < size; i++){
            nums[i] = letters.charAt(i).toUpperCase();
        }
        
        let current = []
        //console.log(results.length)
        dfs(nums, current, rules);
        //console.log(permutation)
        //console.log(results.length)
        for (let i = 0; i < results.length; i++){
           // console.log(results[i])
        }
        
        
    }
    findPermutations(props.size, props.variables, props.rules);
    
    const numbering = [];
    for (let i = 0; i < props.size; i++){
    numbering[i] = i+1 + " ";
    }

    return(
        <div>
            {props.size > 1 ? <div className="possibleGameBoards">Possible Game Boards: {results.length}</div>: null}   
            <div className="numbering"><Row perm = {numbering}/></div>
        <Matrix perms = {results}/>
        </div>
        
    )
}

export default Permutations