import './App.css';
import {useState} from "react";
import Permutations from "./components/Permutations";
import RuleDisplay from "./components/RuleDisplay";
import Row from "./components/Row";

function App() {
  const[inputLength, setInputLength] = useState(0);
  const[inputVariables, setInputVariables] = useState("");
  const[inputRules, setInputRules] = useState([]);

  const handleLengthChange = (event) => {
    
    const number = parseInt(event.target.value);
    if (number > 8 || number < 1) return; 
     setInputLength(number);
  }

  const handleVariableChange = (event) => {
    const variables1 = event.target.value
    const variables = variables1.replace(/\s+/g, '');
    
      const length = variables.length
      if (variables.length > 7) return;
      if (variables.charAt(variables.length-1) === " ") return;
      setInputVariables(variables.toUpperCase());
      setInputLength(length);
    
      console.log({inputVariables});
      console.log(length)
      

  }

  const handleRuleChange = (event) => {
    
  }

  const ruleInputValidMap = new Map();
  //type 1 - needs two letters 
  //type 2 - needs 1 letter and 1 number
  //type 3 - needs 2 letters and 1 number
  //type 4 - 3 letter variables
  //type 5 - 4 letter variables
  //type 6 - 3 letter, 1 number
  //type 7 - letter, number, letter, number
  //type 8 - 1 letter, 2 numbers

  ruleInputValidMap.set("order", 1); 
  ruleInputValidMap.set("mustGoSpot", 2); 
  ruleInputValidMap.set("notAdjacent", 1); 
  ruleInputValidMap.set("directedAdjacent", 1); 
  ruleInputValidMap.set("cantGoSpot", 2); 
  ruleInputValidMap.set("seperation", 3); 
  ruleInputValidMap.set("directedSeperation", 3);
  ruleInputValidMap.set("ifOrderThenOrder", 5); 
  ruleInputValidMap.set("ifOrderThenMustGoSpot", 6); 
  ruleInputValidMap.set("ifCertainSpotMustGoSpot", 7); 
  ruleInputValidMap.set("ifOrderThenAdjacent", 1); 
  ruleInputValidMap.set("minSeperation", 3); 
  ruleInputValidMap.set("minSeperationDirected", 3); 
  ruleInputValidMap.set("twoOptionsBeforeAdjacent", 4); 
  ruleInputValidMap.set("twoOptionsAfterAdjacent", 4); 
  ruleInputValidMap.set("limitedSpot", 3); 
  ruleInputValidMap.set("mustGoSpotOptions", 8);  
  ruleInputValidMap.set("inBetween", 4); 
  ruleInputValidMap.set("notInBetween", 4); 


  const isValidRuleInput = () => {
    const ruleType = document.getElementById("rule").value;
    const variables = document.getElementById("varsNeeded").value;
    const type = ruleInputValidMap.get(ruleType);
    if (type === 1){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || !inputVariables.includes(variables.charAt(1).toUpperCase()) || variables.length != 2) return false;
      return true;
    }
    if (type === 2){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || isNaN(variables.charAt(1)) || variables.charAt(1) > inputLength || variables.length != 2) return false;
      return true;
    }
    if (type === 3){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || !inputVariables.includes(variables.charAt(1).toUpperCase()) || isNaN(variables.charAt(2)) || variables.charAt(2) > inputLength || variables.length != 3) return false;
      return true;
    }

    if (type === 4){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || !inputVariables.includes(variables.charAt(1).toUpperCase()) || !inputVariables.includes(variables.charAt(2).toUpperCase()) || variables.length != 3) return false;
      return true;
    }
    if (type === 5){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || !inputVariables.includes(variables.charAt(1).toUpperCase()) || !inputVariables.includes(variables.charAt(2).toUpperCase()) || !inputVariables.includes(variables.charAt(3).toUpperCase()) || variables.length != 4) return false;
      return true;
    }
    if (type === 6){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || !inputVariables.includes(variables.charAt(1).toUpperCase()) || !inputVariables.includes(variables.charAt(2).toUpperCase()) ||isNaN(variables.charAt(3)) ||variables.charAt(3) > inputLength || variables.length != 4) return false;
      return true;
    }
    if (type === 7){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || isNaN(variables.charAt(1)) || variables.charAt(1) > inputLength || !inputVariables.includes(variables.charAt(2).toUpperCase()) || isNaN(variables.charAt(3)) || variables.charAt(3) > inputLength || variables.length != 4) return false;
      return true;
    }
    if (type === 8){
      if (!inputVariables.includes(variables.charAt(0).toUpperCase()) || isNaN(variables.charAt(1)) || variables.charAt(1) > inputLength || isNaN(variables.charAt(2)) || variables.charAt(2) > inputLength || variables.length != 3) return false;
      return true;
    }
    
  }

  const updateRules = (event) => {
    if (isValidRuleInput() === false) return;
    setInputRules([... inputRules, {
      rule: document.getElementById("rule").value,
      vars: document.getElementById("varsNeeded").value.toUpperCase()
    }])
    
  }
  
  const removeRule = function(rule, vars){
    console.log({inputRules});
    const newRules = [... inputRules];
    for (let i = 0; i < newRules.length; i++){
      if (newRules[i].rule === rule && newRules[i].vars == vars){
        newRules.splice(i, 1);
      }
    setInputRules(newRules);
    }
    console.log({inputRules});
  }
  


  
  return (
    
    
    <div className="App">

      <h1>Logic Game Solver</h1>
      <div className="bodyDiv">
        <div className="welcome">
          <h3>Welcome!</h3>
          <p>This tool is still in the early stages of development. Right now it only handles linear games. In the future I plan to have it support double layer linear games, as well as grouping and hybrid games.</p>

          <h3>Instructions:</h3>
          <p>Enter the first letter of the variables for the game without any spaces. For example, if your variables are 'A', 'B', 'C', 'D', 'E', enter "ABCDE". The order doesn't matter. </p>
          <p>Once you've entered the variables, it's time to start adding the rules. Select which rule you would like to add via the dropdown.</p>
          <p>Next, input the variables/indexes needed for the rule. At the end of the description of the rule it tells you the format it should be in.</p>
          <p>For example, in the "Order Two Variables" rule there is "(AB)" shown at the end to indicate you need two variables for this rule. The "Variable Must Go Certain Spot" shows "(A1)" because you must input a variable and an index. </p>
          <p>After this, click the "Submit Rule" button and it will be applied to the game if it is properly formatted. You can unapply any rule by clicking the "Remove" button next to it.</p>
          <h3>Input:</h3>
        </div>
        <div className ="input">
        
          
          <div className="variableInput">
            <label>Variables: </label>
            <input name = "variables" type="text" placeholder="First Letter of Variables" maxLength={7} onChange={handleVariableChange}/>
          </div>
          <div className="ruleInput">
            <div className="ruleDropDown">
              <label>Rule: </label>
              <select className="select" name = "rule" id="rule">
                <option value="order">Order Two Variables (AB)</option>
                <option value="mustGoSpot">Variable Must Go Certain Space (A1)</option>
                <option value="cantGoSpot">Variable Cannot Go In Certain Spot (A1)</option>
                <option value="adjacent">Two Variables Must Be Adjacent (AB)</option>
                <option value="directedAdjacent">Two Variables Must Be Adjacent - Ordered (AB)</option>
                <option value="notAdjacent">Two Variables Cannot Be Adjacent (AB)</option>
                <option value="seperation">Two Variables Must Be Seperated By a Certain Value (AB1)</option>
                <option value="directedSeperation">Two Variables Must Be Seperated By a Certain Value - Ordered (AB1)</option>
                <option value="ifOrderThenOrder">If Variable 'A' comes before Variable 'B', Then Order Two Variables(ABCD)</option>
                <option value="ifOrderThenMustGoSpot">If Variable 'A' comes before Variable 'B', Then a Variable Must Go In a Certain Spot (ABC1)</option>
                <option value="ifCertainSpotMustGoSpot">If a Variable Is In a Certain Spot, Then Another Variable Must Be In a Certain Spot(A1B2)</option>
                <option value="ifOrderThenAdjacent">If Variable 'A' comes before Variable 'B', Then They Must Be Adjacent(AB)</option>
                <option value="minSeperation"> Two Variables Must Be Seperated By a Minimum Value (AB1)</option>
                <option value="minSeperationDirected">Two Variables Must Be Seperated By A Minimum Value - Ordered (AB1)</option>
                <option value="twoOptionsBeforeAdjacent">One Of Two Variables Must Be Immediately Before a Certain Variable (ABC)</option>
                <option value="twoOptionsAfterAdjacent">Variable Must Be Immediately Before One of Two Variables (ABC)</option>
                <option value="limitedSpot">Certain Spot Must Be One of Two Variables (AB1)</option>
                <option value="mustGoSpotOptions">Variable Must Be In One Of Two Spots (A12)</option>
                <option value="inBetween"> Variable Must Be In Between Two Variables(ABC)</option>
                <option value="notInBetween"> Variable Cannot Be In Between Two Variables(ABC)</option>

              </select>
              
            </div>
            <div className ="varRuleInput">
            <label>Variable(s) Needed For Rule: </label>
              <input name = "varsNeeded" id = "varsNeeded" type ="text" placeholder="example: AB" maxLength={4}/>
            </div>
            <div className ="ruleButton">
              <button type="button" id="ruleButton" onClick={updateRules}>Submit Rule</button>
            </div>
            {inputRules[0] != null ? <div className="rulesHeader">Current Rules:</div> : null}
          </div>
        </div>

        {inputRules.map((rule, key) =>{
          return (
          <div className="rulesDisplay">
            <RuleDisplay currentRule = {rule} rules = {inputRules}/>
            <button type="button" id="removeRuleButton" className ="removeButton" onClick={event => removeRule(rule.rule, rule.vars)}>Remove</button>
          </div>
          )
        })}
          
          <Permutations size = {inputLength} rules = {inputRules} variables = {inputVariables}/>

      </div>
      
    </div>
    
  );
}

export default App;
/*
<div className="sizeInput">
  <label className="label">Size: </label>
    <input name = "length" type="text" placeholder="Size of gameboard" onChange={handleLengthChange}/>
</div>
*/