import React from 'react'



const RuleDisplay = (props) => {

    const removeRule = function(){
        
    }

    const ruleMap = new Map();
    ruleMap.set("order", "Order Two Variables:");
    ruleMap.set("mustGoSpot", "Variable Must Go Certain Space:");
    ruleMap.set("notAdjacent", "Two Variables Cannot Be Adjacent:");
    ruleMap.set("directedAdjacent", "Two Variables Must Be Adjacent - Ordered:");
    ruleMap.set("adjacent", "Two Variables Must Be Adjacent:");
    ruleMap.set("cantGoSpot", "Variable Cannot Go In Certain Spot:");
    ruleMap.set("seperation", "Two Variables Must Be Seperated By a Certain Value:");
    ruleMap.set("directedSeperation", "Two Variables Must Be Seperated By a Certain Value - Ordered):");
    ruleMap.set("ifOrderThenOrder", "If Two Variables Are Ordered, Then Order Other Two Variables:");
    ruleMap.set("ifOrderThenMustGoSpot", "If Two Variables are Ordered, Then A Variable Must Go In A Certain Spot:");
    ruleMap.set("ifCertainSpotMustGoSpot", "If A Variable Is In A Certain Spot, Then Another Variable Must Be In A Certain Spot:");
    ruleMap.set("ifOrderThenAdjacent", "If Two Variables Are Ordered, Then Those Two Variables Must Be Adjacent:");
    ruleMap.set("minSeperation", "Two Variables Must Be Seperated By A Minimum Value:");
    ruleMap.set("minSeperationDirected", "Two Variables Must Be Seperated By A Minimum Value - Ordered:");
    ruleMap.set("twoOptionsBeforeAdjacent", "One Of Two Variables Must Be Immediately Before A Certain Variable:");
    ruleMap.set("twoOptionsAfterAdjacent", "Variable Must Be Immediately Before One of Two Variables:");
    ruleMap.set("limitedSpot", "Certain Spot Must Be One of Two Variables:");
    ruleMap.set("mustGoSpotOptions", "Certain Variable Must Be In One Of Two Spots:");
    ruleMap.set("inBetween", "Certain Variable Must Be In Between Two Variables:");
    ruleMap.set("notInBetween", "Certain Variable Cannot Be In Between Two Variables:");

    let currentRule = "";
    let currentVars = "";

    const display = function(rule){
        {console.log(rule.vars)};
        currentRule = ruleMap.get(rule.rule) + " ";
        currentVars = rule.vars + " ";
    }
    
    display(props.currentRule);



    
    return(
        <div className ="ruleDisplayed">
        {currentRule} 
        {currentVars}
        </div>
        
    )
}

export default RuleDisplay