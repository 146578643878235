import React from 'react'

const Box = (props) => {
    const letter = props.character;
    return(
        <div className="letter">
        {letter}
        </div>
        
    )
}

export default Box