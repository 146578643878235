import React from 'react'
import Row from "./Row";

const Matrix = (props) => {
    const permutations = props.perms
    return(
        <div className ="board">
        {permutations.map((permutation, key) => {
            return (
            
            <Row perm = {permutation}/>
            
            )
        })}
        
        

        </div>

    )
}

export default Matrix

//map through props.perms and for each one return a </row row = current item in iteration>

/*
const Matrix = (props) => {
    const permutation = props.perm
    return(
        <div>
        {permutation.map((letter, key) => {
            return (
            <div>
            <div><Box character = {letter}/></div>
            </div>
            )
        })}
        
        

        </div>

    )
}

props.rules.map(ruleAndVar => {
    const function = ruleAndVar.rule
    cost variables = ruleAndVar.var
    const var1 = ""
    const var2 = ""

    if (function === order){
        var1 = variables.charAt(0);
        var2 = variables.charAt(1);
        if (order(var1, var2, current)=== false){
            valid = false;
            break;
        } 
    }
    if (function === mustGoSpot){
        var1 = variables.charAt(0);
        if (mustGoSpot(var1, current) === false) {
            valid = false;
            break;
    }

    if (function(var1, var2, current)
})


*/